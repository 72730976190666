<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="店铺名称">
					<el-input v-model="formInline.showname" clearable placeholder="店铺名称"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="formInline.problemStatus" clearable placeholder="状态">
						<el-option v-for="opt in problemStatusOptions" :key="opt.value" :label="opt.label"
							:value="opt.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="问题描述">
					<el-input v-model="formInline.problemDescription" clearable placeholder="问题描述"></el-input>
				</el-form-item>
				<el-form-item label="负责人">
					<el-input v-model="formInline.gridman" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search"><i class="el-icon-search"></i> 查询</el-button>
					<el-button @click="problemDealVisible2 = true" type="warning"
						:disabled="multipleSelection.length == 0?true:false"><i class="el-icon-edit"></i>
						批量处理</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="contbox">
			<div class="totalinfo">
				<div>总问题数：<span>{{ problemData.problemtotal }}</span></div>
				<div>本月上报：<span>{{ problemData.monthtotal }}</span></div>
				<div>待处理：<span>{{ problemData.processcount }}</span></div>
				<div>已处理：<span>{{ problemData.finishcount }}</span></div>
			</div>
			<el-table :data="tableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
				:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center" :selectable="checkSelectSet">
				</el-table-column>
				<el-table-column width="110" align="center" prop="problem_status" label="处理状态"
					:formatter="(row) => formatterStatus(row.problem_status, problemStatusOptions)">
				</el-table-column>
				<el-table-column width="180" align="center" prop="create_time" label="发现时间">
				</el-table-column>
				<el-table-column height show-overflow-tooltip width="200px" align="center" prop="showname" label="店铺名称">
				</el-table-column>
				<el-table-column height show-overflow-tooltip align="center" prop="ninetypename" label="场所类型"
					width="120px">
				</el-table-column>
				<el-table-column width="180" align="center" prop="gridman_name" label="负责人">
				</el-table-column>
				<el-table-column width="110" align="center" prop="period" label="巡防周期"
					:formatter="(row) => formatterStatus(row.period, periodOptions)">
				</el-table-column>
				<el-table-column align="center" prop="address" label="巡防地址">
				</el-table-column>
				<el-table-column align="center" fixed="right" label="操作" width="180">
					<template slot-scope="scope">
						<!-- problem_status，0的状态是待处理 -->
						<el-button v-if="scope.row.problem_status == 0" @click="dealClick(scope.row)" type="primary"
							size="mini">处理</el-button>
						<el-button @click="viewClick(scope.row)" type="success" size="mini">查看</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div class="pagebox">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
				</el-pagination>
			</div>
		</div>

		<el-dialog title="巡防问题详情" :close-on-click-modal="false" :visible.sync="detailVisible" width="1000px"
			@closed="detailTaskId = null">
			<detail-cpn :taskId="detailTaskId"></detail-cpn>
		</el-dialog>

		<el-dialog title="问题处理" :close-on-click-modal="false" :visible.sync="problemDealVisible" width="1000px">
			
			<div class="pane_cont" v-if="taskInfo.address">
				<el-col :span="8">
					<div>店铺名称：<span>{{ taskInfo.showname }}</span></div>
				</el-col>
				<el-col :span="8">
					<div>场所类型：<span>{{ taskInfo.ninetypename }}</span></div>
				</el-col>
				<el-col :span="8">
					<div>社区管理员：<span>{{ taskInfo.name }}</span></div>
				</el-col>
				<el-col :span="8">
					<div>店铺管理人：<span>{{ taskInfo.siteman }}</span></div>
				</el-col>
				<el-col :span="8">
					<div>管理人电话：<span>{{ taskInfo.sitemanphone }}</span></div>
				</el-col>
				<el-col :span="8">
					<div>巡防周期：<span>{{ getOptionName(taskInfo.period,periodOptions) }}</span></div>
				</el-col>
				<!-- <el-col :span="24">
					<div>经营范围：<span>{{ taskInfo.operate }}</span></div>
				</el-col> -->
				<el-col :span="24">
					<div>巡防地址：<span>{{ taskInfo.address }}</span></div>
				</el-col>
			</div>
			<div class="problemdesc" v-if="taskDealInfo.create_time">
				
				<div id="container" v-if="taskInfo.addressxy" style="height: 200px; width: 90%; margin: 10px auto 20px;"></div>
				<div class="box">
					<div class="filed">巡防时间</div>
					<div>{{ taskDealInfo.doing_time }}</div>
					<div v-if="taskDealInfo.gridman_name">，{{ taskDealInfo.gridman_name }}</div>
					<div v-if="taskDealInfo.problem == 1">，<span style="color: #ee9934 ">发现问题</span></div>
				</div>
				<div class="box" v-if="taskDealInfo.problem_description">
					<div class="filed">问题描述</div>
					<div style="color: #dd4a68;" v-html="taskDealInfo.problem_description"></div>
				</div>
				<div class="box" v-if="taskDealInfo.problem_attachment_path && taskDealInfo.problem_attachment_path.length">
					<div class="filed">相关图片</div>
					<div class="imgs">
						<!-- <img v-for="(imgurl,index) in taskDealInfo.problem_attachment_path" :key="index" :src="imgurl" alt="">   -->
						<el-image style="height: 90px; width: auto; margin-right: 10px;"
							v-for="(imgurl,index) in taskDealInfo.problem_attachment_path" :key="index" :src="imgurl"
							:preview-src-list="taskDealInfo.problem_attachment_path">
						</el-image>
					</div>
				</div>
				<!-- <div class="box">
					<div class="filed">上报地址</div>
					<div id="container2" style="height: 200px;width: calc(95% - 120px);"></div>
				</div> -->
			</div>
			<el-form :model="form" :rules="rules" ref="form">
				<el-form-item label="问题处理描述" :label-width="formLabelWidth" prop="dealDescription">
					<el-input type="textarea" v-model="form.dealDescription" placeholder="问题处理描述"></el-input>
				</el-form-item>
				<el-form-item label="问题相关图片" :label-width="formLabelWidth" prop="dealAttachmentIds">
					<el-upload ref="upload" action="" accept="image/png, image/jpeg, image/.jpg"
						list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
						:on-change="handleChange" :file-list="fileList" :auto-upload="false">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<el-input v-show="false" v-model="form.dealAttachmentIds"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('form')">取 消</el-button>
				<el-button type="primary" @click="sureProcess('form')">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog append-to-body title="问题处理" :close-on-click-modal="false" :visible.sync="problemDealVisible2"
			width="1000px">
			<el-form :model="form2" :rules="rules" ref="form2">
				<el-form-item label="问题处理描述" :label-width="formLabelWidth" prop="dealDescription">
					<el-input rows="5" type="textarea" v-model="form2.dealDescription" placeholder="问题处理描述"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm2('form2')">取 消</el-button>
				<el-button type="primary" @click="sureProcess2('form2')">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import detailCpn from './detail'
	import {
		problemPage,
		problemTotal,
		problemDetail,
		processProblem,
		processProblems
	} from '@/api/ninesiteproblem'
	import {
		problemStatusOptions
	} from './dict'
	import {
		periodOptions
	} from '../patrolTasks/dict'

	export default {
		name: "",
		components: {
			detailCpn
		},
		props: {},
		data() {
			return {
				problemDealVisible2: false,
				form2: {
					dealDescription: '已阅',
				},
				multipleSelection: [],
				formInline: {
					gridman: '',
					problemStatus: '',
					problemDescription: '',
					showname: ''
				},
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				totalRow: 0,
				formLabelWidth: '120px',
				problemStatusOptions,
				periodOptions,
				problemData: {},
				detailVisible: false,
				detailTaskId: null,
				form: {
					dealDescription: '',
					dealAttachmentIds: ''
				},
				problemDealVisible: false,
				dialogImageUrl: '',
				dialogVisible: false,
				fileList: [],
				processId: '',
				rules: {
					dealDescription: [{
						required: true,
						message: '请输入问题处理描述',
						trigger: 'change'
					}]
				},
				taskDealInfo: {},
				taskInfo:{},
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();
			this.getProblemTotalData();
		},
		computed: {},
		methods: {
			getOptionName(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			},
			sureProcess2(formName) {
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						that.$confirm('是否确认批量处理?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							processProblems({
								ids: that.multipleSelection.join(','),
								dealDescription: that.form2.dealDescription
							}).then(res => {
								if (res.result == 200) {
									that.$message.success('操作成功');
									that.resetForm2(formName);
									that.search();
								} else {
									that.$message.warning(res.description)
								}
							})
						}).catch(() => {
							console.log('error submit!!');
						});

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm2(formName) {
				this.$refs[formName].resetFields();
				this.problemDealVisible2 = false;
			},
			checkSelectSet(row, index) {
				if (row.problem_status == 0) {
					return true;
				} else {
					return false;
				}
			},
			handleSelectionChange(val) {
				let ids = [];
				for (let item of val) {
					ids.push(item.uuid);
				}
				this.multipleSelection = ids;
			},
			//加载地图
			getmap(center) {
				// lazyAMapApiLoaderInstance.load().then(() => {
				// 	let map = new AMap.Map('container', {
				// 		center,
				// 		zoom: 20,
				// 	})

				// 	let marker = new AMap.Marker({
				// 		position: center,
				// 		offset: new AMap.Pixel(-13, -30)
				// 	});
				// 	marker.setMap(map);
				// })
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('container', {
						center,
						zoom: 20,
					})

					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			getmap2(center) {
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('container2', {
						center,
						zoom: 20,
					})

					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			//巡防任务列表
			getList() {
				problemPage({
					gridman: this.formInline.gridman,
					problemStatus: this.formInline.problemStatus,
					problemDescription: this.formInline.problemDescription,
					showname: this.formInline.showname,
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.result == 200) {
						let list = res.detail.list;
						for (let item of list) {
							item.problem_description = item.problem_description.split('/n').join('\n');
						}
						this.tableData = list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取巡防问题统计
			getProblemTotalData() {
				problemTotal().then(res => {
					if (res.result == 200) {
						this.problemData = res.detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取任务详情
			getInfo() {
				problemDetail({
					id: this.processId
				}).then(res => {
					if (res.result == 200) {
						this.taskInfo = res.detail.task;
						let log = res.detail.log;
						log.problem_description = log.problem_description.split('/n').join('<br/>');
						this.taskDealInfo = log;
						if(this.taskInfo.addressxy){
							this.getmap(this.taskInfo.addressxy.split(','));
						}
						// this.getmap2(this.taskDealInfo.problem_xy.split(','));
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//查询
			search() {
				this.currentPage = 1;
				this.getList();
			},
			//查看
			viewClick(row) {
				this.detailVisible = true;
				this.detailTaskId = row.uuid;
			},
			//处理
			dealClick(row) {
				this.processId = row.uuid;
				this.problemDealVisible = true;
				this.getInfo();
			},
			/**
			 * 字段格式化
			 * @param {Number|String} value
			 * @param {Array} list
			 * @return {String}
			 */
			formatterStatus(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			},
			//列表图 文件状态改变时的钩子
			handleChange(file, fileList) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
					return false;
				}

				let formData = new FormData();
				formData.append('upfilelist', file.raw);
				this.$axios.post('/fileutil/UpLoadFileList', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.result == 200) {
						console.log(res.data.detail)
						file.upId = res.data.detail;
						console.log(fileList)
						let ids = [];
						fileList.map(f => {
							ids.push(f.upId)
						})
						this.form.dealAttachmentIds = ids.length ? ids.join(',') : '';
					} else {
						this.$message.error(res.data.description);
					}
				})

			},
			//列表图 文件列表移除文件时的钩子
			handleRemove(file, fileList) {
				let ids = [];
				fileList.map(f => {
					ids.push(f.upId)
				})
				this.form.dealAttachmentIds = ids.length ? ids.join(',') : '';
			},
			//文件超出个数限制时的钩子
			handleExceed(files, fileList) {
				this.$message.warning('只能选择3个文件!');
			},
			//图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//确认提交处理
			sureProcess(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						processProblem({
							id: this.processId,
							dealDescription: this.form.dealDescription,
							dealAttachmentIds: this.form.dealAttachmentIds,
						}).then(res => {
							if (res.result == 200) {
								console.log(res)
								this.$message.success('操作成功');
								this.resetForm(formName);
								this.search();
							} else {
								this.$message.warning(res.description)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.$refs.upload.clearFiles();
				this.problemDealVisible = false;
			}

		}
	}
</script>

<style lang="scss" scoped>
	.pane_cont {
		padding: 20px;
		overflow: hidden;
	
		.el-row {
			>div {
				margin-bottom: 14px;
				font-weight: bold;
				color: #333;
	
				span {
					font-weight: normal;
					color: #666;
				}
			}
		}
	
		
	}
	.contbox {

		.totalinfo {
			padding: 10px 10px 8px;
			display: flex;

			>div {
				margin-right: 30px;
				font-weight: bold;
				color: #333;

				span {
					font-weight: normal;
					color: #409eff;
					font-size: 16px;
				}
			}
		}

		.pagebox {
			padding: 20px 0;
		}
	}

	.problemdesc {
		min-height: 40px;
		line-height: 1.6em;
		background: #f5f5f5;
		padding: 20px 0 5px;
		margin-bottom: 20px;

		.box {
			display: flex;
			margin-bottom: 10px;

			.filed {
				padding: 0 10px;
				width: 120px;
				min-width: 120px;
				text-align: right;
				box-sizing: border-box;
			}
		}

		.imgs {
			display: flex;
			margin-bottom: 20px;

			img {
				height: 90px;
				width: auto;
				margin-right: 10px;
			}
		}
	}

	.formbox {
		width: 94%;
	}
</style>